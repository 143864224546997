import { required, helpers, email, minLength, requiredIf, maxLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import EventService from '../../service/EventService';
import ApiService from "../../service/ApiService";
import moment from 'moment';

export default {
  data() {
    return {
      v$: useValidate(),
      rowId: "",
      mobile_number: "",
      AadharTabDetails: '',
      voterTabDetails: "",
      LicenseTabDetails: "",
      PanTabDetails: "",
      voterfiltermodalsflag: false,
      ak6: "",
      ak24: "",
      ak94: [],
      ak45: [],
      profession: [],
      ak20: [],
      ak21: '',
      ak22: [],
      ak19: "",
      ak19from: '',
      ak19to: '',
      ak49: "",
      ak44: [],
      ak35: "",
      ak36: '',
      ak85: [],
      ak120: '',
      ak121: [],
      agerangefrom: '',
      agerangeto: '',
      voterprofessionList: [],
      genderList: [
        { label: "Male", value: 1 },
        { label: "Female", value: 2 },
        { label: "Others", value: 3 },
      ],
      ageList: [],
      messageList: [],
      totalRecords: 0,
      page_no: 0,
      selected_tab: 1,
      viewvoterdetailstatus: false,
      pancardnumber: "",
      aadharcardnumber: "",
      pincodevalue: [],
      agerange: '',
      votercardnumber: "",
      drivingnumber: "",
      licencebirthdate: null,
      errpan: "",
      aadharerr: "",
      pincodeerr: '',
      agerangeeerr: '',
      agerangefromeerr: '',
      agerangetoeerr: '',
      voteriderr: "",
      errdriving: "",
      errmsg: "",
      showloader: false,
      errStatus: false,
      selectedvoter: null,
      voterdivdisplayflag: false,
      drivingdivdisplayflag: 0,
      pandivdisplayflag: 0,
      aadhardivdisplayflag: 0,
      mobile_otp_status: false,
      loading: false,
      addvotermobile: "",
      addvotershowloader: false,
      aadharotp: "",

      displayMobilePopUp: false,
      timerMobileInterval: null,
      timerMobileStatus: false,
      timerMobileCount: 120,
      mobileotperr: "",
      callstatus: 0,
      msg: [],
      showloadervotermodal: false,
      showloaderaadharmodal: false,
      showloaderpanmodal: false,
      showloaderdrivingmodal: false,
      clientdesignationList: [],
      editvoterdetailstatus: false,
      countrycode: { value: 101, label: "+91" },
      countryCodeList: [],
      titleList: [],
      edit: {
        primobile: "",
        title: '',
        firstname: '',
        middlename: '',
        lastname: '',
        email: '',
        gender: '',
        genderList: [
          { label: "Male", value: 1 },
          { label: "Female", value: 2 },
          { label: "Other", value: 3 },
        ],
        birthdate: null,
        loksabha: '',
        vidhansabha: '',
        companyname: '',
        designation: '',
        importprofilefile: '',
        editRowId: '',
        voterprofileimg: '',
        fulladdress: '',
        city: "",
        area: "",
        pincode: '',
        landmark: '',
        bloodgroup: '',
        profession: [],
        tagname: [],
      },
      professionList: [
        { label: "Student", value: 1 },
        { label: "Self Employed", value: 2 },
        { label: "Salaried", value: 3 },
        { label: "Business", value: 4 },
        { label: "Farmer", value: 5 },
        { label: "Retired", value: 6 },
        { label: "Housewife", value: 7 },
      ],
      bloodgroupList: [],
      loksabhaList: [],
      vidhansabhaList: [],
      maxDateBirth: moment().subtract(1, "years")._d,
      minDateBirth: moment().subtract(120, "years")._d,
      addvoterloader: false,
      errorprofileimage: '',
      submitted: false,
      errormsg: "",
      searchmobile: '',
      searchmobloader: false,
      searchfullname: '',
      searchnameloader: false,
      searchmobbtnactive: false,
      searchnamebtnactive: false,
      cityList: [],
      areaList: [],
      vidhanloaderflag: false,
      cityloaderflag: false,
      arealoaderflag: false,
      client_info: '',
      voterstorageimgpath: '',
      exitpreviewurl: null,
      user_info: '',
      alloweditvoterfk: '',
      allowdeletevoterfk: '',
      allowshowmobilevoterfk: '',
      allowshowemailvoterfk: '',
      allowshowaadharvoterfk: '',
      allowtouseroutgoingcall: 0,
      voterEditStepFirstStatus: false,
      voterEditStepTwoStatus: false,
      voterEditStepThrirdStatus: false,
      edituser: {
        votertype: '',
        roletype: '',
        userrole: "",
        userdesignation: "",
        teamleadname: '',
        selectedTreeValue: [],
      },
      allow: {
        usermobpermission: '',
        useremailpermission: '',
        useraadharpermission: '',
        usermobapppermission: true,
        useraddvotermanually: '',
        usereditpermission: '',
        displayusermobilenumberpermission: '',
        displayuseremailidpermission: '',
        displaykaryakartamobilenumberpermission: '',
        displaykaryakartaemailidpermission: '',
        displaydeletevoterpermission: '',

        displayaddvoterfamilypermission: '',
        displayaddvoterprofessionalinfopermission: '',
        displaymodifyvoterprofessionalinfopermission: '',
        displaymanagemodifyfamilyrelationpermission: '',
        displayremovememberfromfamilygrouppermission: '',
        displaymakeoutgoingcallvoterpermission: '',

        displayvotertagaddpermission: '',
        displayaddtagresponsepermission: 0,
        displayvotertagremovalpermission: '',
        displayremovaltagresponsepermission: 0,
      },
      userdesignationList: [],
      teamleadList: [],
      editvoterloader: false,
      userrole_list: [],
      expandedKeys: {},
      nodes: [],
      editpermissionloader: false,
      reassignvoterflag: false,
      reasign: {
        assigntype: 1,
        reassignpartno: [],
        reassigntouser: '',
      },
      reassignpartnoList: [],
      reassignbtnloader: false,
      showloaderreassignmodal: false,
      user_storage_fk: '',
      hidesearchname: false,
      hidesearchmob: false,
      assignuserList: [],
      user_team_status_allow: 0,
      alreadyExitMessage: '',
      sendOTPMessage: '',
      alreadyExitVoterMessage: '',
      votergovconfirmdata: [],
      showupdateloader: false,
      confirm_update_popup_status: false,
      confirm_aadhar_update_popup_status: false,
      showconfirmloaderbtn: false,
      showresentloader: false,
      resentotpbtnstatus: false,
      updateVoterBtnStatus: true,
      voterNoFK: '',
      showaconfirmloaderbtn: false,
      aadhar_number_fk: '',
      aadharupdatebtnflag: true,
      usercreateerrormsg: '',
      systemlimiterrormsg: '',

      voterexitid: '',
      addharexitid: '',
      voterexitstatus: '',
      exitvotererrormsg: false,
      callTrigerStatusList: '',
      virtualNumberCount: '',
      virtualNumberList: '',
      taggroupList: [],
      tagfiltergroupList: [],
      // campaign start
      bulkcampaignmodalflag: false,
      bulkwhatsapploader: false,
      capwhats: {
        selectbulkvoter: '',
        whatsapptemplate: '',
        datevariable: null,
        campaignname: ''
      },
      whatsapptemplateList: [],
      todaysDate: new Date(),
      bulkcallloader: false,
      capcall: {
        bulktunefile: null,
        datevariable: null,
        campaignname: '',
        calltemplate: '',

      },
      errorbulktunefile: '',
      exitbulkmp3previewurl: null,
      getcampaigntemplateList: [],
      userList: [],
      voterupdatedisplayflag: false,
      voterarraylength: 0,
      voterinputdisplayflag: false,
      switchuncheck: false,
      dynamicTrueActiveValue: '',
      dynamicFalseInactiveValue: '',
      confirm_popup_status: false,
      showconfirmprivoterloaderbtn: false,
      confirm_tune_id: '',
      voteridToSwitch: '',
      confirm_status_value: '',
      voteridstatusfk: '',
      familyrelationstatus: '',
      familyTabDetails: '',
      // whatsapp changes start
      whatsappmodalsflag: false,
      showloadermodal: false,
      whatsApprowId: "",
      whatsAppChatHistory: [],
      whatsappname: "",
      whatsappnumber: "",
      whatsappvoterprofile: "",
      whatsappaadharprofile: "",
      ApiLoadingSwitch: false,
      showloaderbtn: false,
      uploadfile: null,
      file1: null,
      uploadfilename: null,
      chatInput: "",
      ImagePreviewDialog: false,
      // whatsapp changes end
      updatevoterid: "",
      aadharUpdateBtnDaysMsg: '',
      companyList: [],
      filterareaList: [],
      filterdistrictList: [],
      filtercityList: [],
      isCompanyNameExist: false,
      isDesignationExist: false,
      disabledWhatsAppInputPermission: '',

      cityfilterloaderflag: false,
      areafilterloaderflag: false,
      pincodefilterloaderflag: false,
      filterpincodeList: [],
      deletevotermessage: '',
      confirm_delete_voter_popup_status: false,
      deletevoterfk: "",
      showconfirmdeleteloaderbtn: false,
      // webbot history
      webbotrowId: "",
      webbotChatHistory: [],
      webbotname: "",
      webbotnumber: "",
      webbotvoterprofile: '',
      webbotaadharprofile: '',
      webbotmodalsflag: false,
      showwebbotloadermodal: false,
      ApiWebBotLoadingSwitch: false,
      allowmodifyvoterprofessionalinfo: 0,
      allowtagadditionaccess: '',
      allowtagremoveaccess: '',
    };
  },
  ApiService: null,
  eventService: null,
  created() {
    this.ApiService = new ApiService();
    this.eventService = new EventService();
  },
  validations() {
    return {
      aadharotp: {
        required: helpers.withMessage("Enter OTP", required),
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      edit: {
        primobile: {
          required: helpers.withMessage('Please enter Mobile No.', required), minLength: helpers.withMessage(
            "Mobile no should be min 10 digit",
            minLength(10)
          )
        },
        title: { required: helpers.withMessage('Please select title', required) },
        firstname: {
          required: helpers.withMessage("Please enter first name", required),
        },
        lastname: {
          required: helpers.withMessage("Please enter last name", required),
        },
        email: {
          email: helpers.withMessage('Email address is invalid', email)
        },
        birthdate: { required: helpers.withMessage('Please select birth date', required) },
        loksabha: { required: helpers.withMessage('Please select lok sabha', required) },
        gender: { required: helpers.withMessage('Please select gender', required) },
        vidhansabha: { required: helpers.withMessage('Please select vidhan sabha', required) },
        fulladdress: { required: helpers.withMessage('Please enter full address', required) },
        city: { required: helpers.withMessage('Please select city', required) },
        pincode: {
          required: helpers.withMessage('Please enter pincode', required),
          minLength: helpers.withMessage(
            "Pincode should be min 6 length",
            minLength(6)
          ),
        },
        area: {
          requiredIf: helpers.withMessage(
            "Please select area",
            requiredIf(
              this.areaList != null
            )
          ),
        },
      },
      edituser: {
        votertype: { required: helpers.withMessage('Please select voter type', required) },
        roletype: {
          requiredIf: helpers.withMessage(
            "Please select role type",
            requiredIf(
              this.edituser.votertype == 2 || this.edituser.votertype == 3
            )
          ),
        },
        userrole: {
          requiredIf: helpers.withMessage(
            "Please select role designation",
            requiredIf(
              this.edituser.roletype == 1 && this.edituser.votertype == 2 || this.edituser.votertype == 3
            )
          ),
        },
        selectedTreeValue: {
          requiredIf: helpers.withMessage(
            "Please select module",
            requiredIf(
              this.edituser.roletype == 2
            )
          ),
        },
        userdesignation: {
          requiredIf: helpers.withMessage(
            "Please select designation",
            requiredIf(
              this.edituser.votertype == 2 || this.edituser.votertype == 3
            )
          ),
        },
        teamleadname: {
          requiredIf: helpers.withMessage(
            "Please select teamlead name",
            requiredIf(
              (this.edituser.votertype == 2 || this.edituser.votertype == 3) && this.teamleadList != null
            )
          ),
        },
      },
      reasign: {
        assigntype: { required: helpers.withMessage('Please select reassign by', required) },
        reassignpartno: {
          requiredIf: helpers.withMessage(
            "Please select part number",
            requiredIf(
              this.reasign.assigntype == 2
            )
          )
        },
        reassigntouser: {
          requiredIf: helpers.withMessage(
            "Please select user",
            requiredIf(
              this.reasign.assigntype == 1
            )
          ),
        },
      },
      capwhats: {
        selectbulkvoter: { required: helpers.withMessage('Please select voter', required) },
        whatsapptemplate: { required: helpers.withMessage('Please select WhatsApp template', required) },
        campaignname: { required: helpers.withMessage('Please enter campaign name', required) },
      },
      capcall: {
        bulktunefile: { required: helpers.withMessage('Please select Mp3 file', required) },
        calltemplate: { required: helpers.withMessage('Please select call template', required) },
        campaignname: { required: helpers.withMessage('Please enter campaign name', required) },
      },
    };
  },
  watch: {
    pancardnumber(pancardnumber) {
      this.errmsg = "";
      this.validatePanCardNumber(pancardnumber);
      if (pancardnumber.length == 0) {
        this.errpan = "";
        this.errStatus = false;
      }
    },
    aadharcardnumber(aadharcardnumber) {
      this.errmsg = "";
      this.validateAadharCardNumber(aadharcardnumber);
      if (aadharcardnumber.length == 0) {
        this.aadharerr = "";
        this.errStatus = false;
      }
    },
    votercardnumber(votercardnumber) {
      this.errmsg = "";
      this.validateVoterIdNumber(votercardnumber);
      if (votercardnumber.length == 0) {
        this.voteriderr = "";
        this.errStatus = false;
      }
    },
    agerange(agerange) {
      this.errmsg = "";
      this.validateAgeRangeNumber(agerange);
      if (agerange.length == 0) {
        this.agerangeeerr = "";
        this.errStatus = false;
      }
    },
    agerangefrom(agerangefrom) {
      this.errmsg = "";
      this.validateAgeRangeFromNumber(agerangefrom);
      if (agerangefrom.length == 0) {
        this.agerangefromeerr = "";
        this.errStatus = false;
      }
    },
    agerangeto(agerangeto) {
      this.errmsg = "";
      this.validateAgeRangeToNumber(agerangeto);
      if (agerangeto.length == 0) {
        this.agerangetoeerr = "";
        this.errStatus = false;
      }
    },
    drivingnumber(drivingnumber) {
      this.errmsg = "";
      this.validateDrivingNumber(drivingnumber);
      if (drivingnumber.length == 0) {
        this.errdriving = "";
        this.errStatus = false;
      }
    },
  },
  mounted() {
    this.getvoterlist({
      page_no: this.page_no,
      isTeam: 0,
      ak6: this.ak6,
      ak24: this.ak24,
      profession: this.profession,
      ak21: this.ak21,
      ak22: this.ak22,
      ak19: this.ak19,
      ak19from: this.ak19from,
      ak19to: this.ak19to,
      ak49: this.ak49,
      ak44: this.ak44,
      ak35: this.ak35,
      ak36: this.ak36,
      ak85: this.ak85,
      ak120: this.ak120,
      ak121: this.ak121
    });
    this.getcallbacknumbers();
    this.getalltitle();
    this.getloksabha();
    this.getclientdesignations();
    this.getClientRoles();
    this.getuserdesignation();
    this.getactiveusers();
    this.getbloodgroups();
    this.getModules();
    this.getAgeEvents();
    this.gettags();
    this.getboothnumberlist();
    // this.getareaList();
    this.getdistricts();
    this.getcompanylist();
    this.getcampaigntemplates();
    this.client_info = JSON.parse(localStorage.client_info);
    this.voterstorageimgpath = this.client_info.maa24;
    this.user_info = JSON.parse(localStorage.user);
    this.alloweditvoterfk = this.user_info.ak109;
    this.allowdeletevoterfk = this.user_info.ak165;
    this.allowshowmobilevoterfk = this.user_info.ak105;
    this.allowshowemailvoterfk = this.user_info.ak106;
    this.allowshowaadharvoterfk = this.user_info.ak107;
    this.allowmodifyvoterprofessionalinfo = this.user_info.ak200;
    this.user_storage_fk = this.user_info.user_id;
    if(this.user_info.ak203) {
        this.allowtouseroutgoingcall = this.user_info.ak203;
    }
    this.user_team_status_allow = this.user_info.is_team;
    this.allowtagadditionaccess = this.user_info.ak197;
    this.allowtagremoveaccess = this.user_info.ak198;
    // if (this.user_info.ak165 == 1) {
    //   this.allow.displaydeletevoterpermission = true;
    // } else {
    //   this.allow.displaydeletevoterpermission = false;
    // }
  },
  beforeUnmount() {
    clearInterval(this.timer3);
    clearInterval(this.timer7);
  },
  methods: {
    createOtherCompanyName(e) {
      this.edit.companyname = { value: 0, label: e.target.value };
    },
    toggleCompanyName() {
      this.isCompanyNameExist = !this.isCompanyNameExist;
      this.edit.companyname = '';
    },
    createOtherDesignationName(e) {
      this.edit.designation = { value: 0, label: e.target.value };
    },
    toggleDesginationName() {
      this.isDesignationExist = !this.isDesignationExist;
      this.edit.designation = '';
    },
    // common function start here
    getcompanylist(e) {
      this.ApiService.getcompanylist(e).then((data) => {
        if (data.status == 200) {
          this.companyList = data.data;
        } else {
          this.companyList = null;
        }
      });
    },
    // getareaList(e) {
    //   this.ApiService.getareaList(e).then((data) => {
    //     if (data.status == 200) {
    //       this.filterareaList = data.data;
    //     } else {
    //       this.filterareaList = null;
    //     }
    //   });
    // },
    getdistricts() {
      this.ApiService.getdistricts({ state: 11 }).then((data) => {
        if (data.status == 200) {
          this.filterdistrictList = data.data;
        } else {
          this.filterdistrictList = null;
        }
      });
    },
    getcityListByDIstrict(e) {
      this.cityfilterloaderflag = true;
      this.ak45 = [];
      this.ak44 = [];
      this.ApiService.getCitiesByDistrictIds({ district_ids: e }).then((items) => {
        if (items.success === true) {
          this.filtercityList = items.data;
          this.cityfilterloaderflag = false;
        } else {
          this.cityfilterloaderflag = false;
          this.filtercityList = [];
        }
      });
    },
    getareaListByCity(e) {
      this.areafilterloaderflag = true;
      this.ApiService.getAreasByCityIds({ city_ids: e }).then((items) => {
        if (items.success === true) {
          this.filterareaList = items.data;
          this.areafilterloaderflag = false;

        } else {
          this.areafilterloaderflag = false;
          this.filterareaList = [];
        }
      });
    },
    getpincodeListByArea(e) {
      this.pincodefilterloaderflag = true;
      this.pincodevalue = [];
      this.ApiService.getPincoceByAreaIds({ area_ids: e }).then((items) => {
        if (items.success === true) {
          this.filterpincodeList = items.data;
          var nameArr = e;
          for (let index = 0; index < nameArr.length; index++) {
            let vals = this.filterpincodeList.filter(function (item) {
              return item.value == nameArr[index];
            });
            if (vals.length > 0) {
              this.pincodevalue = [...this.pincodevalue, vals[0]];
            }
          }
          this.pincodefilterloaderflag = false;
        } else {
          this.filterpincodeList = [];
          this.pincodefilterloaderflag = false;
        }
      });
    },
    expandAll() {
      for (let node in this.nodes) {
        this.expandNode(this.nodes[node]);
      }
      this.expandedKeys = { ...this.expandedKeys };
    },
    collapseAll() {
      this.expandedKeys = {};
    },

    expandNode(node) {
      if (node.children && node.children.length) {
        this.expandedKeys[node.key] = true;
        for (let child of node.children) {
          this.expandNode(child);
        }
      }
    },
    getcountrycode(e) {
      this.ApiService.getcountrycode(e).then((data) => {
        if (data.status == 200) {
          this.countryCodeList = data.data;
        }
      });
    },
    getalltitle(e) {
      this.ApiService.getalltitle(e).then((data) => {
        if (data.status == 200) {
          this.titleList = data.data;
        }
      });
    },

    getloksabha(e) {
      this.edit.vidhansabha = '';
      this.ApiService.getloksabha(e).then((data) => {
        if (data.status == 200) {
          this.loksabhaList = data.data;
        }
      });
    },
    getvidhansabhaName(e) {
      this.vidhanloaderflag = true;
      this.edit.vidhansabha = '';
      this.ApiService.getvidhansabha({ lokhsabha_id: e.value }).then((items) => {
        if (items.success === true) {
          this.vidhansabhaList = items.data;
          this.vidhanloaderflag = false;
        } else {
          this.vidhanloaderflag = false;
          this.vidhansabhaList = [];
        }
      });
    },
    getclientdesignations(e) {
      this.ApiService.getclientdesignations(e).then((data) => {
        if (data.status == 200) {
          this.clientdesignationList = data.data;
        }
      });
    },
    getuserdesignation(e) {
      this.ApiService.getuserdesignations(e).then((data) => {
        if (data.status == 200) {
          this.userdesignationList = data.data;
        }
      });
    },
    getactiveusers(e) {
      this.ApiService.getactiveusers(e).then((data) => {
        if (data.status == 200) {
          this.teamleadList = data.data;
          for (let i = 0; i < this.teamleadList.length; i++) {
            let designation = '';
            if (this.teamleadList[i]['ak84'] != null && this.teamleadList[i]['ak84'] != '') {
              designation = ' (' + this.teamleadList[i]['ak84'] + ')';
            }
            this.teamleadList[i]['label'] = this.teamleadList[i]['label'] + '' + designation;
          }
        } else {
          this.teamleadList = null;
        }
      });
    },
    getusersexcludingloginuser(e) {
      this.ApiService.getusersexcludingloginuser(e).then((data) => {
        if (data.status == 200) {
          this.userList = data.data;
          for (let i = 0; i < this.userList.length; i++) {
            let designation = '';
            if (this.userList[i]['ak84'] != null && this.userList[i]['ak84'] != '') {
              designation = ' (' + this.userList[i]['ak84'] + ')';
            }
            this.userList[i]['label'] = this.userList[i]['label'] + '' + designation;
          }
          this.assignuserList = this.userList;
          this.showloaderreassignmodal = false;
        }
      });
    },
    getboothnumberlist(e) {
      this.ApiService.getboothnumberlist(e).then((data) => {
        if (data.status == 200) {
          this.reassignpartnoList = data.data;
        }
      });
    },
    getcities(e) {
      this.cityloaderflag = true;
      this.edit.city = '';
      this.edit.area = '';
      this.ApiService.getcities(e).then((data) => {
        if (data.success == true) {
          this.cityList = data.data;
          this.cityloaderflag = false;
          this.showloadervotermodal = false;
          this.areaList = null;
        } else {
          this.cityloaderflag = false;
          this.cityList = [];
          this.areaList = null;
          this.showloadervotermodal = false;
        }
      });
    },
    getAreaByCityId(e) {
      this.arealoaderflag = true;
      this.edit.area = '';
      this.ApiService.getareas({ city_id: e.value }).then((data) => {
        if (data.success == true) {
          this.areaList = data.data;
          this.arealoaderflag = false;
        } else {
          this.arealoaderflag = false;
          this.areaList = null;
        }
      });
    },
    getModules(ev) {
      this.ApiService.getModules(ev).then(data => {
        if (data.status == 200) {
          this.nodes = data.data;
        }
      })
    },
    getClientRoles(e) {
      this.ApiService.getclientroles(e).then((data) => {
        if (data.status == 200) {
          this.userrole_list = data.data;
        }
      });
    },
    getbloodgroups(e) {
      this.ApiService.getbloodgroups(e).then((data) => {
        if (data.status == 200) {
          this.bloodgroupList = data.data;
        } else {
          this.bloodgroupList = null;
        }
      });
    },

    getProfessions(e) {
      this.ApiService.getProfessions(e).then((data) => {
        if (data.status == 200) {
          this.voterprofessionList = data.data;
        } else {
          this.voterprofessionList = null;
        }
      });
    },
    gettags(e) {
      this.ApiService.gettags(e).then((data) => {
        if (data.status == 200) {
          this.tagfiltergroupList = data.data;
          if(this.allowtagadditionaccess == 1) {
            this.taggroupList = data.data;
          } else {
            this.taggroupList = null;
          }
        } else {
          this.tagfiltergroupList = null;
          this.taggroupList = null;
        }
      });
    },
    getAgeEvents(e) {
      this.eventService.getEvents(e).then((data) => {
        this.ageList = data;
      });
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    onPasteMobile(event) {
      let mobvalue = event.clipboardData.getData('text');
      if (/^[0-9]+$/.test(mobvalue)) return true;
      else event.preventDefault();
    },
    mobileSearchFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }

      var mobileInput = document.getElementById("searchmobInput");
      if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
        $event.preventDefault();
      }
    },
    onlyNumberAadharMobile($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
      var mobileAadharInput = document.getElementById("formAadharMobileNoInput");
      if ((mobileAadharInput.value == '' || mobileAadharInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
        $event.preventDefault();
      }
    },
    isLetterWithSpace(e) {
      let charspace = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z\s]+$/.test(charspace)) return true;
      else e.preventDefault();
    },
    isLetterWithOutSpace(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
    handleEditGender() {
      if (this.edit.title.value == 1) {
        this.edit.gender = {
          label: "Male",
          value: 1,
        }
      } else if (this.edit.title.value == 2 || this.edit.title.value == 3) {
        this.edit.gender = {
          label: "Female",
          value: 2,
        }
      } else {
        this.edit.gender = '';
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      } else {
        return 'N/A';
      }
    },
    format_datemonth(value) {
          if (value) {
              return moment(String(value)).format("DD MMMM YYYY");
          }
      },
    format_timestamp(value) {
      if (value) {
        var localTime = new Date(value * 1000).toUTCString();
        return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
      } else {
        return 'N/A';
      }
    },
    maskedNumber(getvalue) {
      const maskedDigits = 'X'.repeat(getvalue.length - 4);
      const FirstThreeDigits = getvalue.slice(0, 2);
      const lastThreeDigits = getvalue.slice(-2);
      return FirstThreeDigits + maskedDigits + lastThreeDigits;
    },
    maskedEmail(getvalue) {
      const [name, domain] = getvalue.split('@');
      const [firstDomain, sublastdomain, lastdomain] = domain.split('.');
      if (name.length == 1 && !lastdomain) {
        return `${name}@${new Array(firstDomain.length).join('*')}.${sublastdomain}`;
      } else if (name.length == 1 && lastdomain) {
        return `${name}@${new Array(firstDomain.length).join('*')}.${sublastdomain}.${lastdomain}`;
      } else {
        return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
      }
    },
    redirectmyreporturl() {
      this.$router.push("/voters/myvoterreport");
    },
    redirectteamvoterurl() {
      this.$router.push("/voters/teamsvoters");
    },
    selectedtab(e) {
      this.selected_tab = e;
    },
    // common function end here
    // call trigger function start here
    getcallbacknumbers(e) {
      this.ApiService.getcallbacknumbers(e).then((data) => {
        if (data.success == true) {
          this.virtualNumberList = data.records;
          this.virtualNumberCount = data.count;
        }
      });
    },
    toggleOperatorCallList(id, event) {
      this.$refs[id].toggle(event);
    },

    CallTrigerOperatorNumber(voterid, mobileno, settingid, event) {
      setTimeout(() => {
        this.$refs[voterid].hide(event);
      }, 1000);
      this.ApiService.triggercalltomeraneta({ voterid: voterid, mobile_number: mobileno, settingId: settingid }).then(
        (items) => {
          if (items.success === true) {
            this.callstatus = 0;
            var successMsg = items.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
          } else {
            var errorMsg = items.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.callstatus = 0;
          }
        }
      );
    },
    // call trigger function end here
    // voter listing mounted function start here
    getvoterlist(e) {
      this.loading = true;
      this.ApiService.getvoterlist(e).then((data) => {
        if (data.success == true) {
          this.messageList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
          this.searchnameloader = false;
          this.searchmobloader = false;
          this.hidesearchname = false;
          this.hidesearchmob = false;
        } else {
          this.messageList = null;
          this.totalRecords = 0;
          this.loading = false;
          this.searchnameloader = false;
          this.searchmobloader = false;
          this.hidesearchname = false;
          this.hidesearchmob = false;
        }
      });
    },
    getvoterlistreload() {
      this.loading = true;
      this.ApiService.getvoterlist({ isTeam: 0 }).then((data) => {
        if (data.success == true) {
          this.messageList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.messageList = null;
          this.totalRecords = 0;
          this.loading = false;
        }
      });
    },
    changePage(event) {
      this.page_no = event.page;
      this.getvoterlist({ page_no: this.page_no, isTeam: 0, });
    },
    // voter listing mounted function end here
    // filter function start here
    voterFilterModalOpen() {
      this.getProfessions();
      this.voterfiltermodalsflag = true;
    },
    voterFilterModalClose() {
      this.voterfiltermodalsflag = false;
    },
    btnFilterSearch(
      profession,
      ak20,
      ak22,
      agerange,
      agerangefrom,
      agerangeto,
      ak94,
      ak45,
      ak44,
      pincodevalue,
      votercardnumber,
      aadharcardnumber,
      ak85,
      ak120,
      ak121
    ) {
      if (!this.agerangeeerr && !this.pincodeerr && !this.voteriderr && !this.aadharerr && !this.agerangefromeerr && !this.agerangetoeerr) {
        this.voterfiltermodalsflag = false;
        this.profession = profession;
        this.ak20 = ak20;
        this.ak22 = ak22;
        this.agerange = agerange;
        this.agerangefrom = agerangefrom;
        this.agerangeto = agerangeto;
        this.ak94 = ak94;
        this.ak45 = ak45;
        this.ak44 = ak44;
        this.pincodevalue = pincodevalue;
        this.votercardnumber = votercardnumber;
        this.aadharcardnumber = aadharcardnumber;
        this.ak85 = ak85;
        this.ak120 = ak120;
        this.ak121 = ak121;
        this.getvoterlist({
          page_no: this.page_no,
          isTeam: 0,
          profession: this.profession,
          ak20: this.ak20,
          ak22: this.ak22,
          ak19: this.agerange,
          ak19from: this.agerangefrom,
          ak19to: this.agerangeto,
          ak94: this.ak94,
          ak45: this.ak45,
          ak44: this.ak44,
          ak49: this.pincodevalue,
          ak35: this.votercardnumber,
          ak36: this.aadharcardnumber,
          ak85: this.ak85,
          ak120: this.ak120,
          ak121: this.ak121
        });
      }
    },
    resetVoterFilter() {
      this.profession = null;
      this.ak20 = null;
      this.ak21 = "";
      this.ak22 = null;
      this.agerange = "";
      this.agerangefrom = '';
      this.agerangeto = '';
      this.ak94 = null;
      this.ak45 = null;
      this.ak44 = null;
      this.pincodevalue = null;
      this.aadharcardnumber = "";
      this.votercardnumber = '';
      this.agerangeeerr = '';
      this.pincodeerr = '';
      this.voteriderr = '';
      this.aadharerr = '';
      this.ak85 = null;
      this.ak120 = '';
      this.ak121 = null;
      this.getvoterlist({
        page_no: this.page_no,
        isTeam: 0,
      });
      this.voterfiltermodalsflag = false;
    },
    onlysingleagechange($event) {
      this.agerangefrom = '';
      this.agerangeto = '';
      this.agerangefromeerr = '';
      this.agerangetoeerr = '';
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    agerangechange($event) {
      this.agerange = '';
      this.agerangeeerr = '';
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    // filter function end here
    // Form validation common function start here
    validatePanCardNumber(val) {
      this.errStatus = false;
      if (!this.validPAN(val)) {
        this.errpan = "Please enter valid pan number";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.errpan = "";
        return true;
      }
    },

    validPAN: function (e) {
      var re = /([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}$/;
      return re.test(e);
    },
    validateAadharCardNumber(e) {
      this.errStatus = false;
      if (!this.validAadharCard(e)) {
        this.aadharerr = "Please enter valid Aadhar No.";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.aadharerr = "";
        return true;
      }
    },

    validAadharCard: function (e) {
      var re = /^((?!(0))[0-9]{12})$/;
      return re.test(e);
    },
    validatePinCodeNumber(e) {
      this.errStatus = false;
      if (!this.validPinCode(e)) {
        this.pincodeerr = "Please enter valid pincode";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.pincodeerr = "";
        return true;
      }
    },
    validPinCode: function (e) {
      var re = /^((?!(0))[0-9]{6,6})$/;
      return re.test(e);
    },
    validateAgeRangeNumber(e) {
      this.errStatus = false;
      if (!this.validAgeRange(e)) {
        this.agerangeeerr = "Please enter minimum 1 digit";
        this.errStatus = true;
      }
      if (this.agerange == 0) {
        this.agerangeeerr = "Please enter valid age";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.agerangeeerr = "";
        return true;
      }
    },
    validAgeRange: function (e) {
      var re = /([0-9]){1,2}$/;
      return re.test(e);
    },
    validateAgeRangeFromNumber(e) {
      this.errStatus = false;
      if (!this.validAgeFromRange(e)) {
        this.agerangefromeerr = "Please enter minimum 1 digit";
        this.errStatus = true;
      }
      if (this.agerangefrom == 0) {
        this.agerangefromeerr = "Please enter valid age";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.agerangefromeerr = "";
        return true;
      }
    },
    validAgeFromRange: function (e) {
      var re = /([0-9]){1,2}$/;
      return re.test(e);
    },
    validateAgeRangeToNumber(e) {
      this.errStatus = false;
      if (!this.validAgeToRange(e)) {
        this.agerangetoeerr = "Please enter minimum 1 digit";
        this.errStatus = true;
      }
      if (parseInt(this.agerangeto) <= parseInt(this.agerangefrom)) {
        this.agerangetoeerr = "Please enter valid age range";
        this.errStatus = true;
      }
      if (this.agerangeto == 0) {
        this.agerangetoeerr = "Please enter valid age";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.agerangetoeerr = "";
        return true;
      }

    },
    validAgeToRange: function (e) {
      var re = /([0-9]){1,2}$/;
      return re.test(e);
    },
    validateVoterIdNumber(e) {
      this.errStatus = false;
      if (!this.validVoterId(e)) {
        this.voteriderr = "Please enter valid voter id";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.voteriderr = "";
        return true;
      }
    },

    validVoterId: function (e) {
      var re = /([A-Za-z]){3}([0-9]){7}$/;
      return re.test(e);
    },

    validateDrivingNumber(e) {
      this.errStatus = false;
      if (!this.validDrivingNo(e)) {
        this.errdriving = "Please enter valid driving licence no";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.errdriving = "";
        return true;
      }
    },

    validDrivingNo: function (e) {
      var re = /([A-Za-z]){2}([0-9]){13}$/;
      return re.test(e);
    },
    // Form validation common function end here
    // Edit Voter form function start here
    voterDetailEditModalOpen(e) {
      this.editvoterdetailstatus = true;
      this.voterEditStepFirstStatus = true;
      this.voterEditStepTwoStatus = false;
      this.voterEditStepThrirdStatus = false;
      this.getclientdesignations();
      this.getuserdesignation();
      this.showloadervotermodal = true;
      this.isCompanyNameExist = false;
      this.isDesignationExist = false;
      this.edit.aadhar = e.ak112;
      this.edit.primobile = '';
      this.edit.firstname = '';
      this.edit.middlename = '';
      this.edit.lastname = '';
      this.edit.email = '';
      this.edit.gender = '';
      this.edit.birthdate = null;
      this.edit.loksabha = '';
      this.edit.vidhansabha = '';
      this.edit.companyname = '';
      this.edit.designation = '';
      this.edit.importprofilefile = '';
      this.edit.editRowId = '';
      this.edit.fulladdress = '';
      this.edit.pincode = '';
      this.edit.landmark = '';
      this.edit.city = '';
      this.edit.area = '';
      this.edit.bloodgroup = '';
      this.edit.profession = '';
      this.edit.tagname = null;
      this.exitpreviewurl = null;
      this.voterexitid = '';
      this.addharexitid = '';
      this.voterexitstatus = '';
      this.getcities();
      this.deletevotermessage = '';
      this.edit.editRowId = e.ak1;
      if (this.allowshowmobilevoterfk == 1) {
        this.edit.primobile = e.ak24;
      } else if (this.allowshowmobilevoterfk == 0) {
        this.edit.primobile = this.maskedNumber(e.ak24);
      }
      this.voterexitid = e.ak35;
      this.addharexitid = e.ak36;
      this.voterexitstatus = e.is_exist;
      if (e.ak11 != null && e.ak11 != "") {
        this.edit.title = {
          label: e.ak11,
          value: e.ak10,
        };
      }
      if (e.ak29 != 1) {
        this.checkAddUserAuth();
      } else {
        this.checkAddVoterAuth();
      }
      this.edit.firstname = e.ak7;
      this.edit.middlename = e.ak8;
      this.edit.lastname = e.ak9;
      if (this.allowshowemailvoterfk == 1) {
        this.edit.email = e.ak23;
      } else if (this.allowshowemailvoterfk == 0) {
        if (e.ak23 != null && e.ak23 != "") {
          this.edit.email = this.maskedEmail(e.ak23);
        } else {
          this.edit.email = '';
        }
      }
      // this.edit.email = e.ak23;
      if (e.ak18 != null && e.ak18 != "") {
        this.edit.birthdate = new Date(e.ak18);
      }
      if (e.ak22 == 1) {
        this.edit.gender = {
          label: "Male",
          value: e.ak22,
        }
      } else if (e.ak22 == 2) {
        this.edit.gender = {
          label: "Female",
          value: e.ak22,
        }
      } else if (e.ak22 == 3) {
        this.edit.gender = {
          label: "Other",
          value: e.ak22,
        }
      }
      if (e.ak63 != null && e.ak63 != "") {
        this.edit.loksabha = {
          label: e.ak63,
          value: e.ak62,
        };
      }
      if (e.ak61 != null && e.ak61 != "") {
        this.edit.vidhansabha = {
          label: e.ak61,
          value: e.ak60,
        };
      }
      if (e.ak84 != null && e.ak84 != "") {
        this.edit.designation = {
          label: e.ak84,
          value: e.ak83,
        };
      }
      if (e.ak90 != null && e.ak90 != "") {
        this.edit.importprofilefile = "https://storage.googleapis.com/" + this.voterstorageimgpath + "/voterphoto/" + e.ak90;
      } else {
        this.edit.importprofilefile = null;
      }
      if (e.ak88 != null && e.ak88 != '') {
        this.edit.companyname = {
          label: e.ak88,
          value: e.ak145,
        };
      }
      this.edituser.votertype = e.ak29;
      if (e.ak110 != null && e.ak110 != '') {
        this.edituser.userdesignation = {
          label: e.ak111,
          value: e.ak110,
        };
      }
      if (e.ak101 != null && e.ak101 != '') {
        this.edituser.teamleadname = {
          label: e.ak103,
          value: e.ak101,
        };
      }
      this.edituser.userrole = e.ak39;
      if (e.ak29 == 3) {
        if (e.ak39 != null && e.ak39 != '') {
          this.edituser.roletype = 2;
        } else {
          this.edituser.roletype = 1;
        }
      } else {
        this.edituser.roletype = '';
      }
      if (e.ak41 != null) {
        let arrConverted = e.ak41.split(",");
        this.edituser.selectedTreeValue = arrConverted;
        for (let index = 0; index < arrConverted.length; index++) {
          let idxx = arrConverted[index];
          this.edituser.selectedTreeValue = {
            ...this.edituser.selectedTreeValue,
            [idxx]: { checked: true, partialChecked: false },
          };
        }
      }
      this.edit.fulladdress = e.ak51;
      this.edit.pincode = e.ak58;
      this.edit.landmark = e.ak59;
      if (e.ak55 != null && e.ak55 != "") {
        this.edit.city = {
          label: e.ak55,
          value: e.ak54,
        };
      }
      if (e.ak21 != null && e.ak21 != '') {
        this.edit.bloodgroup = {
          label: e.ak21,
          value: e.ak20,
        };
      }
      if (e.ak122 != null && e.ak122 != '') {
        var labels = e.ak122.split(',');
        var values = e.ak121.split(',');
        var tagnames = [];
        for (var i = 0; i < labels.length; i++) {
          var tags = [];
          tags['label'] = labels[i];
          tags['value'] = values[i];
          Object.assign({}, tags);
          var tagobject = Object.assign({}, tags);
          tagnames.push(tagobject);
        }
        this.edit.tagname = tagnames;
      }
      if (e.ak66 != null && e.ak66 != '') {
        this.edit.profession = {
          label: e.ak67,
          value: e.ak66,
        };
      }
      if (e.ak53 != null && e.ak53 != "") {
        this.edit.area = {
          label: e.ak53,
          value: e.ak52,
        };
      }
      if (e.ak105 == 1) {
        this.allow.usermobpermission = true;
    } else {
        this.allow.usermobpermission = false;
    }

    if (e.ak106 == 1) {
        this.allow.useremailpermission = true;
    } else {
        this.allow.useremailpermission = false;
    }

    if (e.ak107 == 1) {
        this.allow.useraadharpermission = true;
    } else {
        this.allow.useraadharpermission = false;
    }

    if (e.ak108 == 1) {
        this.allow.usermobapppermission = true;
    } else {
        this.allow.usermobapppermission = true;
    }
    if(e.ak207 == 1) {
        this.allow.useraddvotermanually = true;
    } else {
        this.allow.useraddvotermanually = false;
    }
    if (e.ak109 == 1) {
        this.allow.usereditpermission = true;
    } else {
        this.allow.usereditpermission = false;
    }

    if (e.ak123 == 1) {
        this.allow.displayusermobilenumberpermission = true;
    } else {
        this.allow.displayusermobilenumberpermission = false;
    }

    if (e.ak124 == 1) {
        this.allow.displayuseremailidpermission = true;
    } else {
        this.allow.displayuseremailidpermission = false;
    }
    if (e.ak125 == 1) {
        this.allow.displaykaryakartamobilenumberpermission = true;
    } else {
        this.allow.displaykaryakartamobilenumberpermission = false;
    }
    if (e.ak126 == 1) {
        this.allow.displaykaryakartaemailidpermission = true;
    } else {
        this.allow.displaykaryakartaemailidpermission = false;
    }
    if (e.ak165 == 1) {
        this.allow.displaydeletevoterpermission = true;
    } else {
        this.allow.displaydeletevoterpermission = false;
    }
    if (e.ak196 == 1) {
        this.allow.displayaddvoterfamilypermission = true;
    } else {
        this.allow.displayaddvoterfamilypermission = false;
    }
    if (e.ak199 == 1) {
        this.allow.displayaddvoterprofessionalinfopermission = true;
    } else {
        this.allow.displayaddvoterprofessionalinfopermission = false;
    }
    if (e.ak200 == 1) {
        this.allow.displaymodifyvoterprofessionalinfopermission = true;
    } else {
        this.allow.displaymodifyvoterprofessionalinfopermission = false;
    }
    if (e.ak201 == 1) {
        this.allow.displaymanagemodifyfamilyrelationpermission = true;
    } else {
        this.allow.displaymanagemodifyfamilyrelationpermission = false;
    }
    if (e.ak202 == 1) {
        this.allow.displayremovememberfromfamilygrouppermission = true;
    } else {
        this.allow.displayremovememberfromfamilygrouppermission = false;
    }
    if (e.ak197 == 1) {
        this.allow.displayvotertagaddpermission = true;
    } else {
        this.allow.displayvotertagaddpermission = false;
    }
    if(e.ak204) {
        this.allow.displayaddtagresponsepermission = e.ak204;
    }
    if (e.ak198 == 1) {
        this.allow.displayvotertagremovalpermission = true;
    } else {
        this.allow.displayvotertagremovalpermission = false;
    }
    if(e.ak205) {
        this.allow.displayremovaltagresponsepermission = e.ak205;
    }
    if (e.ak203 == 1) {
        this.allow.displaymakeoutgoingcallvoterpermission = true;
    } else {
        this.allow.displaymakeoutgoingcallvoterpermission = false;
    }
    },
    voterDetailEditModalClose() {
      this.editvoterdetailstatus = false;
      setTimeout(() => {
        this.v$.$reset();
      }, 0);
      this.expandedKeys = {};
    },
    updateVoterDetailbtn() {
      this.submitted = true;
      this.errormsg = '';
      this.v$.edit.$validate();
      var formData = new FormData();
      formData.append("ak1", this.edit.editRowId);
      formData.append("ak24", this.edit.primobile);
      formData.append("ak10", this.edit.title.value);
      formData.append("ak11", this.edit.title.label);
      formData.append("ak7", this.edit.firstname);
      if (this.edit.middlename) {
        formData.append("ak8", this.edit.middlename);
      }
      formData.append("ak9", this.edit.lastname);
      var fullname = this.edit.title.label + ' ' + this.edit.firstname + ' ' + this.edit.middlename + ' ' + this.edit.lastname;
      formData.append('ak5', fullname);
      if (this.edit.email) {
        formData.append("ak23", this.edit.email);
      }
      formData.append('ak18', moment(this.edit.birthdate).format("YYYY-MM-DD"));
      formData.append("ak22", this.edit.gender.value);
      formData.append("ak62", this.edit.loksabha.value);
      formData.append("ak63", this.edit.loksabha.label);
      formData.append("ak60", this.edit.vidhansabha.value);
      formData.append("ak61", this.edit.vidhansabha.label);
      formData.append('ak51', this.edit.fulladdress);
      formData.append('ak58', this.edit.pincode);
      if (this.edit.landmark) {
        formData.append("ak59", this.edit.landmark);
      }
      if (this.edit.tagname) {
        formData.append('tagname', JSON.stringify(this.edit.tagname));
      }
      if (this.edit.bloodgroup) {
        formData.append("ak20", this.edit.bloodgroup.value);
        formData.append("ak21", this.edit.bloodgroup.label);
      }
      if (this.edit.profession) {
        formData.append("ak66", this.edit.profession.value);
        formData.append("ak67", this.edit.profession.label);
      }
      if (this.edit.city) {
        formData.append("ak54", this.edit.city.value);
        formData.append("ak55", this.edit.city.label);
      }
      if (this.edit.area) {
        formData.append("ak52", this.edit.area.value);
        formData.append("ak53", this.edit.area.label);
      }
      if (this.edit.companyname) {
        formData.append("ak145", this.edit.companyname.value);
        formData.append("ak88", this.edit.companyname.label);
      }
      if (this.edit.designation) {
        formData.append("ak83", this.edit.designation.value);
        formData.append("ak84", this.edit.designation.label);
      }
      if (this.edit.voterprofileimg) {
        formData.append("voterprofileimg", this.edit.voterprofileimg);
      }
      if (!this.v$.edit.$error && !this.errorprofileimage) {
        this.addvoterloader = true;
        this.ApiService.updatevotersdetails(formData).then((data) => {
          if (data.status == 200) {
            this.addvoterloader = false;
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.getvoterlistreload();
            this.voterEditStepFirstStatus = false;
            this.voterEditStepTwoStatus = true;
          } else {
            this.voterEditStepFirstStatus = true;
            this.addvoterloader = false;
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          }
        });
      } else {
        this.$error;
      }
    },
    updateUserPermissionbtn() {
      this.v$.edituser.$validate();
      let fields = {};
      fields["ak1"] = this.edit.editRowId;
      fields["ak29"] = this.edituser.votertype;
      fields["ak24"] = this.edit.primobile;
      fields["ak7"] = this.edit.firstname;
      if (this.edituser.votertype == 2 || this.edituser.votertype == 3) {
        if (this.edituser.roletype) {
          fields["role_type"] = this.edituser.roletype;
        }
        if (this.edituser.userdesignation.value) {
          fields["ak110"] = this.edituser.userdesignation.value;
        }
        if (this.edituser.userdesignation.label) {
          fields["ak111"] = this.edituser.userdesignation.label;
        }
        if (this.edituser.teamleadname) {
          fields["ak101"] = this.edituser.teamleadname.value;
          fields["ak103"] = this.edituser.teamleadname.label;
        }
        if (this.edituser.userrole) {
          fields["ak39"] = this.edituser.userrole;
        }
        if (this.edituser.selectedTreeValue) {
          fields["modules"] = this.edituser.selectedTreeValue
        }
      }
      if (!this.v$.edituser.$error && !this.exitvotererrormsg) {
        this.editvoterloader = true;
        this.ApiService.updateclientuserpermissions(fields).then((items) => {
          if (items.status == 200) {
            this.editvoterloader = false;
            this.$toast.open({
              message: items.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.voterEditStepTwoStatus = false;
            this.voterEditStepThrirdStatus = true;
            if (this.edituser.votertype == 1) {
              this.voterEditStepTwoStatus = false;
              this.editvoterdetailstatus = false;
              this.getvoterlistreload();
            } else if (this.edituser.votertype == 2 || this.edituser.votertype == 3) {
              this.voterEditStepTwoStatus = false;
              this.voterEditStepThrirdStatus = true;
            }
          } else {
            this.$toast.open({
              message: items.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.editvoterloader = false;
            this.voterEditStepTwoStatus = true;
            this.usercreateerrormsg = items.msg;
          }
        });
      }
    },
    updateClientUserPermission() {
      let fields = {};
      fields["ak1"] = this.edit.editRowId;
      if (this.allow.usermobpermission == true) {
        fields["ak105"] = 1;
      } else if (this.allow.usermobpermission == false) {
        fields["ak105"] = 0;
      }

      if (this.allow.useremailpermission == true) {
        fields["ak106"] = 1;
      } else if (this.allow.useremailpermission == false) {
        fields["ak106"] = 0;
      }

      if (this.allow.useraadharpermission == true) {
        fields["ak107"] = 1;
      } else if (this.allow.useraadharpermission == false) {
        fields["ak107"] = 0;
      }

      if (this.allow.usermobapppermission == true) {
        fields["ak108"] = 1;
      } else if (this.allow.usermobapppermission == false) {
        fields["ak108"] = 0;
      }
      if(this.allow.useraddvotermanually == true) {
          fields["ak207"] = 1;
      } else if (this.allow.useraddvotermanually == false) {
          fields["ak207"] = 0;
      }
      if (this.allow.usereditpermission == true) {
        fields["ak109"] = 1;
      } else if (this.allow.usereditpermission == false) {
        fields["ak109"] = 0;
      }
      if (this.allow.displayusermobilenumberpermission == true) {
        fields["ak123"] = 1;
      } else if (this.allow.displayusermobilenumberpermission == false) {
        fields["ak123"] = 0;
      }

      if (this.allow.displayuseremailidpermission == true) {
        fields["ak124"] = 1;
      } else if (this.allow.displayuseremailidpermission == false) {
        fields["ak124"] = 0;
      }

      if (this.allow.displaykaryakartamobilenumberpermission == true) {
        fields["ak125"] = 1;
      } else if (this.allow.displaykaryakartamobilenumberpermission == false) {
        fields["ak125"] = 0;
      }

      if (this.allow.displaykaryakartaemailidpermission == true) {
        fields["ak126"] = 1;
      } else if (this.allow.displaykaryakartaemailidpermission == false) {
        fields["ak126"] = 0;
      }
      if (this.allow.displaydeletevoterpermission == true) {
        fields["ak165"] = 1;
      } else if (this.allow.displaydeletevoterpermission == false) {
        fields["ak165"] = 0;
      }
      if (this.allow.displayaddvoterfamilypermission == true) {
        fields["ak196"] = 1;
    } else if (this.allow.displayaddvoterfamilypermission == false) {
        fields["ak196"] = 0;
    }
    if (this.allow.displayaddvoterprofessionalinfopermission == true) {
        fields["ak199"] = 1;
    } else if (this.allow.displayaddvoterprofessionalinfopermission == false) {
        fields["ak199"] = 0;
    }
    if (this.allow.displaymodifyvoterprofessionalinfopermission == true) {
        fields["ak200"] = 1;
    } else if (this.allow.displaymodifyvoterprofessionalinfopermission == false) {
        fields["ak200"] = 0;
    }
    if (this.allow.displaymanagemodifyfamilyrelationpermission == true) {
        fields["ak201"] = 1;
    } else if (this.allow.displaymanagemodifyfamilyrelationpermission == false) {
        fields["ak201"] = 0;
    }
    if (this.allow.displayremovememberfromfamilygrouppermission == true) {
        fields["ak202"] = 1;
    } else if (this.allow.displayremovememberfromfamilygrouppermission == false) {
        fields["ak202"] = 0;
    }
    if (this.allow.displayvotertagaddpermission == true) {
      fields["ak197"] = 1;
        if(this.allow.displayaddtagresponsepermission) {
            fields["ak204"] = this.allow.displayaddtagresponsepermission;
        }
    } else if (this.allow.displayvotertagaddpermission == false) {
        fields["ak197"] = 0;
        fields["ak204"] = 0;
    }
    if (this.allow.displayvotertagremovalpermission == true) {
        fields["ak198"] = 1;
        if(this.allow.displayremovaltagresponsepermission) {
            fields["ak205"] = this.allow.displayremovaltagresponsepermission;
        }
    } else if (this.allow.displayvotertagremovalpermission == false) {
        fields["ak198"] = 0;
        fields["ak205"] = 0;
    }
    if (this.allow.displaymakeoutgoingcallvoterpermission == true) {
        fields["ak203"] = 1;
    } else if (this.allow.displaymakeoutgoingcallvoterpermission == false) {
        fields["ak203"] = 0;
    }
      this.editpermissionloader = true;
      this.ApiService.updateuserallowpermissions(fields).then((items) => {
        if (items.status == 200) {
          this.editpermissionloader = false;
          this.$toast.open({
            message: items.message,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.voterEditStepThrirdStatus = false;
          this.editvoterdetailstatus = false;
          this.getvoterlistreload();
        } else {
          this.editpermissionloader = false;
          this.$toast.open({
            message: items.message,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        }
      });
    },
    handleUploadVoterProfile() {
      var file = this.$refs.voterprofileimg.files[0];
      let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      if (!file.size > 2048) {
        this.errorprofileimage = 'File size cannot exceed more than 2MB';
      } else if (!file.name.match(allowedExtensions)) {
        this.errorprofileimage = 'Invalid file type';
      } else {
        this.errorprofileimage = '';
        this.edit.voterprofileimg = this.$refs.voterprofileimg.files[0];
        this.exitpreviewurl = this.edit.voterprofileimg;
      }
    },
    createVoterEditDesignation(event) {
      if (event && event.value) {
        if (Number.isInteger(event.value)) {
          this.edit.designation = { value: event.value, label: event.label };
        } else {
          this.edit.designation = { value: 0, label: event.label };
        }
      }
    },
    checkAddUserAuth() {
      if (this.voterexitstatus == 1 || (this.voterexitid == '' || this.voterexitid == null) && (this.addharexitid == '' || this.addharexitid == null)) {
        this.exitvotererrormsg = true;
      } else {
        this.exitvotererrormsg = false;
      }
    },
    checkAddVoterAuth() {
      this.exitvotererrormsg = false;
    },
    // Edit Voter form function end here
    // Search Voter Header Input function start here
    searchMobVoter(
      searchmobile
    ) {
      this.hidesearchmob = true;
      this.searchmobloader = true;
      this.ak24 = searchmobile;
      this.getvoterlist({
        page_no: this.page_no,
        ak24: this.ak24,
        isTeam: 0,
      });
      this.searchmobbtnactive = true;
    },
    searchMobVoterStop() {
      this.searchmobbtnactive = false;
      this.searchmobile = "";
      this.getvoterlist({
        page_no: this.page_no,
        isTeam: 0,
      });
      this.searchmobloader = false;
    },
    searchFnameVoter(
      searchfullname
    ) {
      this.hidesearchname = true;
      this.searchnameloader = true;
      this.ak6 = searchfullname;
      this.getvoterlist({
        page_no: this.page_no,
        ak6: this.ak6,
        isTeam: 0,
      });
      this.searchnamebtnactive = true;
    },
    searchNameVoterStop() {
      this.searchnamebtnactive = false;
      this.searchfullname = "";
      this.getvoterlist({
        page_no: this.page_no,
        isTeam: 0,
      });
      this.searchnameloader = false;
    },
    // Search Voter Header Input function end here

    // Reassign Voter Form function start here
    reassignVoterToUserModalOpen() {
      this.reassignvoterflag = true;
      this.showloaderreassignmodal = true;
      this.reasign.assigntype = 1;
      this.reasign.reassignpartno = null;
      this.reasign.reassigntouser = '';
      this.getusersexcludingloginuser();
    },
    reassignVoterToUserModalClose() {
      this.reassignvoterflag = false;

    },
    reAssignToUserBtn() {
      let vals = this.selectedvoter.map(function (item) {
        return item.ak1;
      });
      this.selectedreassignvoter = vals;
      this.v$.reasign.$validate();
      let fields = {};
      fields["voters"] = this.selectedreassignvoter;
      fields["assign_type"] = this.reasign.assigntype;
      if (this.reasign.reassignpartno && this.reasign.assigntype == 2) {
        fields["ak85"] = JSON.stringify(this.reasign.reassignpartno);
      }
      if (this.reasign.reassigntouser.value && this.reasign.assigntype == 1) {
        fields["ak1"] = this.reasign.reassigntouser.value;
      }
      if (this.reasign.reassigntouser.label && this.reasign.assigntype == 1) {
        let assignvalue = this.reasign.reassigntouser.label.split(" (");
        fields["ak5"] = assignvalue[0];
      }
      if (this.reasign.reassigntouser.label && this.reasign.assigntype == 1) {
        fields["ak111"] = this.reasign.reassigntouser.ak111;
      }
      if (!this.v$.reasign.$error) {
        this.reassignbtnloader = true;
        this.ApiService.assignVoterToUser(fields).then((items) => {
          if (items.status == 200) {
            this.reassignbtnloader = false;
            this.$toast.open({
              message: items.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.reassignvoterflag = false;
            this.reasign.assigntype = '';
            this.reasign.reassignpartno = null;
            this.reasign.reassigntouser = '';
            this.selectedvoter = null;
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.getvoterlistreload();
          } else {
            this.$toast.open({
              message: items.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.reassignbtnloader = false;
            this.reassignvoterflag = true;
          }
        });
      }
    },
    // Reassign Voter Form function end here
    // bulk campaign function start here
    bulkCampaignModalOpen() {
      this.bulkcampaignmodalflag = true;
      this.capcall.bulktunefile = null;
      this.errorbulktunefile = '';
      this.exitbulkmp3previewurl = null;
      this.selectedbulkCampWhatsAppvoter = null;
      this.selectedbulkCampCallvoter = null;
    },
    bulkCampaignModalModalClose() {
      this.bulkcampaignmodalflag = false;
      this.capwhats.selectbulkvoter = '';
      this.capwhats.whatsapptemplate = '';
      this.capwhats.campaignname = '';
      this.capwhats.datevariable = null;
      this.capcall.bulktunefile = null;
      this.capcall.calltemplate = null;
      this.capcall.datevariable = null;
      this.errorbulktunefile = '';
      this.exitbulkmp3previewurl = null;
      this.selectedbulkCampWhatsAppvoter = null;
      this.selectedbulkCampCallvoter = null;
      this.capcall.campaignname = null;
      setTimeout(() => {
        this.v$.$reset();
      }, 0);
    },
    assignBulkWhatsAppCampaignBtn() {
      let vals = this.selectedvoter.map(function (item) {
        return item.ak1;
      });

      this.selectedbulkCampWhatsAppvoter = vals;
      this.v$.capwhats.$validate();
      let fields = {};
      fields["ah2"] = this.selectedbulkCampWhatsAppvoter;
      fields["ah8"] = this.capwhats.selectbulkvoter;
      fields["ah12"] = this.capwhats.whatsapptemplate;
      fields["ah3"] = this.capwhats.campaignname;
      if (this.capwhats.datevariable != null) {
        fields["datevariable"] = moment(this.capwhats.datevariable).format("YYYY-MM-DD");
      }
      if (!this.v$.capwhats.$error) {
        this.bulkwhatsapploader = true;
        this.ApiService.createWhatsappBulkCampaign(fields).then((items) => {
          if (items.status == 200) {
            this.bulkwhatsapploader = false;
            this.$toast.open({
              message: items.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.bulkCampaignModalModalClose();
          } else {
            this.$toast.open({
              message: items.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.bulkwhatsapploader = false;
          }
        });
      }
    },
    handleUploadBulkCampaignCall() {
      var file = this.$refs.bulktuneref.files[0];
      let allowedExtensions = /(\.mp3)$/i;
      if (!file.size > 2048) {
        this.errorbulktunefile = 'File size cannot exceed more than 2MB';
      } else if (!file.name.match(allowedExtensions)) {
        this.errorbulktunefile = 'Invalid file type';
      } else {
        this.errorbulktunefile = '';
        this.capcall.bulktunefile = this.$refs.bulktuneref.files[0];
        this.exitbulkmp3previewurl = this.capcall.bulktunefile;
      }
    },
    assignBulkCallCampaignBtn() {
      let vals = this.selectedvoter.map(function (item) {
        return item.ak1;
      });
      this.selectedbulkCampCallvoter = vals;
      var formData = new FormData();
      this.v$.capcall.$validate();
      formData.append("ah2", this.selectedbulkCampCallvoter);
      if (this.capcall.bulktunefile != null) {
        formData.append("bulktunefile", this.capcall.bulktunefile);
      }
      if (this.capcall.calltemplate != null) {
        formData.append("ah12", this.capcall.calltemplate);
      }
      if (this.capcall.campaignname != null) {
        formData.append("ah3", this.capcall.campaignname);
      }
      if (this.capwhats.datevariable != null) {
        formData.append("datevariable", moment(this.capwhats.datevariable).format("YYYY-MM-DD"));
      }

      if (!this.v$.capcall.$error && !this.errorbulktunefile) {
        this.bulkcallloader = true;
        this.ApiService.createCallsBulkCampaign(formData).then((data) => {
          if (data.success == true) {
            this.bulkcallloader = false;
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.bulkCampaignModalModalClose();
          } else {
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.bulkcallloader = false;
          }
        });
      }
    },
    getcampaigntemplates(e) {
      this.ApiService.getcampaigntemplatesList(e).then((data) => {
        if (data.status == 200) {
          this.getcampaigntemplateList = data.data;
        }
      });
    },
    // bulk campaign function end here
    // Voter Details View Modal Common function start here
    voterdetailModalOpen(e) {
      this.rowId = '';
      this.mobile_number = '';
      this.voterNoFK = '';
      this.searchaadharmobile = '';
      this.licencebirthdate = null;
      this.rowId = e.ak1;
      this.familyrelationstatus = e.az6;
      this.mobile_number = e.ak24;
      this.voterNoFK = e.ak35;
      this.searchaadharmobile = e.ak24;
      if (e.ak18 != null && e.ak18 != "") {
        this.licencebirthdate = new Date(e.ak18);
      } else {
        this.licencebirthdate = null;
      }
      this.viewvoterdetailstatus = true;
      this.getvoterdetails();
      this.votercardnumber = "";
      this.aadharcardnumber = "";
      this.aadharotp = "";
      this.pancardnumber = "";
      this.drivingnumber = "";
      this.aadhar_number_fk = '';
      this.hideaadharmobile = false;
    },

    voterDetailModalClose() {
      this.viewvoterdetailstatus = false;
    },
    // Voter Details View Modal Common function end here
    // Get Driving Voter details function start here
    getlicensedetails() {
      this.errmsg = '';
      this.showloaderdrivingmodal = true;
      this.ApiService.getlicensedetails({ ak1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.LicenseTabDetails = data.data;
          this.drivingdivdisplayflag = 1;
          this.showloaderdrivingmodal = false;
        } else {
          this.LicenseTabDetails = '';
          this.drivingdivdisplayflag = 0;
          this.showloaderdrivingmodal = false;
        }
      });
    },
    getdrivingdetails() {
      this.errmsg = '';
      this.showloader = true;
      let fields = {};
      fields["ak1"] = this.rowId;
      fields["update"] = 0;
      fields["drivingnumber"] = this.drivingnumber;
      fields["dob"] = moment(this.licencebirthdate).format('YYYY-MM-DD');
      this.ApiService.getdrivinglicensedetails(fields).then((data) => {
        if (data.success == true) {
          this.LicenseTabDetails = data.data;
          this.drivingdivdisplayflag = 1;
          this.showloader = false;
        } else {
          this.errmsg = data.message;
          this.LicenseTabDetails = '';
          this.drivingdivdisplayflag = 0;
          this.showloader = false;
        }
      });
    },
    // Get Driving Voter details function end here
    // Get Pan Card Voter details function start here
    getpandetails() {
      this.errmsg = '';
      this.showloaderpanmodal = true;
      this.ApiService.getpandetails({ ak1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.PanTabDetails = data.data;
          this.pandivdisplayflag = 1;
          this.showloaderpanmodal = false;
        } else {
          this.errmsg = data.message;
          this.pandivdisplayflag = 0;
          this.showloaderpanmodal = false;
        }
      });
    },
    getpancarddetails() {
      this.errmsg = '';
      this.showloader = true;
      this.ApiService.getpancarddetails({ ak1: this.rowId, update: 0, pancardnumber: this.pancardnumber }).then((data) => {
        if (data.status == 200) {
          this.PanTabDetails = data.data;
          this.pandivdisplayflag = 1;
          this.showloader = false;
          this.errmsg = '';
        } else {
          this.errmsg = data.message;
          this.pandivdisplayflag = 0;
          this.showloader = false;
        }
      });
    },
    // Get Pan Card Voter details function end here
    // View voter details tab function start here 
    getvoterdetails() {
      this.voterTabDetails = '';
      this.voterarraylength = '';
      this.showloadervotermodal = true;
      this.ApiService.addvoterdetails({ ak1: this.rowId, update: 0, ak24: this.mobile_number }).then((data) => {
        if (data.success == true) {
          this.voterTabDetails = data.data;

          this.voterarraylength = data.data.length;
          this.voterdivdisplayflag = true;
          this.voterinputdisplayflag = true;
          this.showloadervotermodal = false;
          this.alreadyExitVoterMessage = '';
        } else {
          this.voterinputdisplayflag = true;
          this.voterdivdisplayflag = false;
          this.showloadervotermodal = false;
          this.alreadyExitVoterMessage = '';
        }
      });
    },
    confirmModalOpen(e) {
      this.confirm_tune_id = e.ak1;
      this.voteridToSwitch = e.ak35;
      this.voteridstatusfk = e.ab26
      this.confirm_popup_status = true;
      this.confirm_status_value = '';
    },
    confirmModalClose() {
      this.confirm_popup_status = false;
      this.confirm_tune_id = '';
      this.voteridToSwitch = '';
      this.confirm_status_value = '';
      this.getvoterdetails();
    },
    changePrimaryVoterStatus() {
      let fields = {};
      fields["ak1"] = this.confirm_tune_id;
      fields["ak35"] = this.voteridToSwitch;
      if (this.confirm_status_value == 0 || this.confirm_status_value == false) {
        fields["ab26"] = this.voteridstatusfk;
      }
      if (this.confirm_status_value == 1 || this.confirm_status_value == true) {
        fields["ab26"] = this.voteridstatusfk;
      }
      this.showconfirmprivoterloaderbtn = true;
      this.ApiService.voterCardSetAsPrimary(fields).then((items) => {
        if (items.status === 200) {
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.showconfirmprivoterloaderbtn = false;
          this.getvoterdetails();
          this.confirm_tune_id = '';
          this.voteridToSwitch = '';
          this.confirm_status_value = '';
          this.voteridstatusfk = '';
          this.confirm_popup_status = false;
          setTimeout(() => {
            this.v$.$reset();
          }, 0);
        } else {
          this.showconfirmprivoterloaderbtn = false;
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        }
      });
    },
    confirmUpdateModalOpen(e) {
      this.updatevoterid = e.ak35;
      this.confirm_update_popup_status = true;
    },
    confirmUpdateModalClose() {
      this.confirm_update_popup_status = false;
    },
    fetchvoteriddetails() {
      this.showloader = true;
      let fields = {};
      fields["ak24"] = this.mobile_number;
      fields["ak1"] = this.rowId;
      fields["voter_id"] = this.votercardnumber;
      fields["update"] = 0;
      this.ApiService.addvoterdetails(fields).then((items) => {
        if (items.success === true) {
          if (items.is_exists == 1) {
            this.alreadyExitVoterMessage = items.message;
            this.votercardnumber = '';
            this.showloader = false;
            this.voterinputdisplayflag = true;
            this.voterdivdisplayflag = true;
            this.voterupdatedisplayflag = false;
            var errorMsg = items.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          } else {
            this.showloader = false;
            this.alreadyExitVoterMessage = items.message;
            this.voterTabDetails = items.data;
            this.voterinputdisplayflag = true;
            this.voterdivdisplayflag = false;
            this.voterupdatedisplayflag = true;
            this.votergovconfirmdata = items.data;
            var successMsg = items.message;
            this.$toast.open({
              message: successMsg,
              type: "warning",
              duration: 3000,
              position: "top-right",
            });
          }
        } else {
          var errorMsgs = items.message;
          this.$toast.open({
            message: errorMsgs,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.showloader = false;
        }
      });
    },
    updateVoterPermission() {
      this.showconfirmloaderbtn = true;
      let fields = {};
      fields["ak24"] = this.mobile_number;
      fields["ak1"] = this.rowId;
      fields["voter_id"] = this.updatevoterid;
      fields["update"] = 1;
      this.ApiService.addvoterdetails(fields).then((items) => {
        if (items.success === true) {
          this.updatevoterid = '';
          this.showloader = false;
          this.voterupdatedisplayflag = false;
          this.voterdivdisplayflag = false;
          this.confirm_update_popup_status = false;
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.showconfirmloaderbtn = false;
          // this.alreadyExitVoterMessage = '';
          // this.updateVoterBtnStatus = false;
          this.getvoterdetails();
          this.votergovconfirmdata = [];
          this.votercardnumber = '';
          this.showloader = false;
        } else {
          this.alreadyExitVoterMessage = items.message;
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.showconfirmloaderbtn = false;
        }
      });
    },
    // View voter details tab function end here
    // Get Aadhar Detail Form Function start here
    // First Get Aadhar Details API call
    getaadhardetails() {
      this.aadharUpdateBtnDaysMsg = '';
      this.showloaderaadharmodal = true;
      this.ApiService.getaadhardetails({ ak1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.AadharTabDetails = data.data;
          if (this.AadharTabDetails.ak36 != null && this.AadharTabDetails.ak36 != '') {
            this.aadhardivdisplayflag = 1;
            if (this.AadharTabDetails.update == 0) {
              this.aadharupdatebtnflag = false;
              if (this.AadharTabDetails.update_days != 30) {
                this.aadharUpdateBtnDaysMsg = 'You can update Aadhar Card details after ' + (30 - this.AadharTabDetails.update_days) + ' Days';
              }
            } else {
              this.aadharupdatebtnflag = true;
              this.aadharUpdateBtnDaysMsg = '';
            }
          } else {
            this.aadhardivdisplayflag = 0;
          }
          this.showloaderaadharmodal = false;
        } else {
          this.showloaderaadharmodal = false;
        }
      });
    },
    confirmAadharUpdateModalOpen(aadhar) {
      this.confirm_aadhar_update_popup_status = true;
      this.aadhar_number_fk = aadhar;
    },
    confirmAadharUpdateModalClose() {
      this.confirm_aadhar_update_popup_status = false;
    },
    updateAadharReloadPermission() {
      this.showaconfirmloaderbtn = true;
      this.confirm_aadhar_update_popup_status = false;
      let fields = {};
      fields["ak24"] = this.mobile_number;
      fields["aadhar_number"] = this.aadhar_number_fk;
      fields["ak1"] = this.rowId;
      fields["update"] = 1;
      this.showloaderaadharmodal = true;
      this.ApiService.generateaadharotp(fields).then((items) => {
        if (items.success === true) {
          this.aadharcardnumber = this.aadhar_number_fk;
          this.aadhardivdisplayflag = 0;
          this.showupdateloader = false;
          this.showaconfirmloaderbtn = false;
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.displayMobilePopUp = true;
          this.startMobileTimer();
          this.timerMobileStatus = true;
          this.aadharotp = "";
          this.mobileotperr = '';
          this.showloader = false;
          this.sendOTPMessage = items.message;
          this.mobile_otp_status = 0;
          this.showresentloader = false;
          this.resentotpbtnstatus = false;
          this.showloaderaadharmodal = false;
        } else {
          this.resentotpbtnstatus = false;
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.showresentloader = false;
          this.sendOTPMessage = items.message;
          this.showaconfirmloaderbtn = false;
          this.showloaderaadharmodal = false;
          this.systemlimiterrormsg = items.message;
        }
      });
    },
    sendAadharOtp() {
      this.showloader = true;
      if (
        this.aadharcardnumber != "" &&
        this.aadharcardnumber != null &&
        this.aadharcardnumber.length == 12
      ) {
        let fields = {};
        fields["ak24"] = this.mobile_number;
        fields["aadhar_number"] = this.aadharcardnumber;
        fields["ak1"] = this.rowId;
        fields["update"] = 0;
        this.ApiService.generateaadharotp(fields).then((items) => {
          if (items.success === true) {
            // is_exits == 1 message display 
            // is_exits == 0 OTP Send
            if (items.data.is_exits == 1) {
              this.alreadyExitMessage = items.message;
              this.showloader = false;
              this.aadharcardnumber = '';
              this.displayMobilePopUp = false;
              var errorMsg = items.message;
              this.$toast.open({
                message: errorMsg,
                type: "error",
                duration: 3000,
                position: "top-right",
              });
            } else {
              this.alreadyExitMessage = '';
              this.displayMobilePopUp = true;
              this.startMobileTimer();
              this.timerMobileStatus = true;
              this.aadharotp = "";
              this.showloader = false;
              this.sendOTPMessage = items.message;
              var successMsg = items.message;
              this.$toast.open({
                message: successMsg,
                type: "success",
                duration: 3000,
                position: "top-right",
              });
            }
          } else {
            this.alreadyExitMessage = '';
            var errorMsgs = items.message;
            this.$toast.open({
              message: errorMsgs,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.showloader = false;
            this.aadharcardnumber = '';
            this.aadharotp = "";
            this.timerMobileStatus = false;
            this.systemlimiterrormsg = items.message;
          }
        });
      } else {
        this.$toast.open({
          message: "Please enter valid Aadhar No.",
          type: "warning",
          duration: 3000,
          position: "top-right",
        });
      }
    },
    resendAadharOtp() {
      this.showresentloader = true;
      let fields = {};
      fields["ak24"] = this.mobile_number;
      fields["aadhar_number"] = this.aadharcardnumber;
      fields["ak1"] = this.rowId;
      fields["update"] = 0;
      fields["resend_otp"] = 1;
      this.ApiService.generateaadharotp(fields).then((items) => {
        if (items.success === true) {
          this.showupdateloader = false;
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.displayMobilePopUp = true;
          this.startMobileTimer();
          this.timerMobileStatus = true;
          this.aadharotp = "";
          this.mobileotperr = '';
          this.showloader = false;
          this.sendOTPMessage = items.message;
          this.mobile_otp_status = 0;
          this.showresentloader = false;
          this.resentotpbtnstatus = false;
        } else {
          this.resentotpbtnstatus = false;
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.showresentloader = false;
          this.sendOTPMessage = items.message;
          this.systemlimiterrormsg = items.message;
        }
      });
    },
    verifyAadharOtp() {
      this.showloader = true;
      this.mobile_otp_status = 0;
      let fields = {};
      fields["ak24"] = this.mobile_number;
      fields["ak1"] = this.rowId;
      fields["otp"] = this.aadharotp;
      fields["aadhar_number"] = this.aadharcardnumber;
      fields["update"] = 1;
      this.ApiService.getaadharcarddetails(fields).then((items) => {
        if (items.success === true) {
          this.showloaderaadharmodal = false;
          this.aadhardivdisplayflag = 1;
          this.aadharcardnumber = '';
          this.aadharerr = '';
          this.sendOTPMessage = '';
          this.displayMobilePopUp = false;
          this.aadharotp = '';
          this.timerMobileStatus = false;
          this.timerMobileCount = 120;
          this.alreadyExitMessage = '';
          this.AadharTabDetails = items.data;
          this.showloader = false;
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.aadharupdatebtnflag = false;
        }
        else if (items.status == 500) {
          this.mobile_otp_status = 1;
        } else {
          this.sendOTPMessage = '';
          this.sendOTPMessage = '';
          this.showloader = false;
          this.mobileotperr = items.message;
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.timerMobileStatus = false;
          this.timerMobileCount = 0;
          this.aadharotp = "";
          this.showloader = false;
          this.resentotpbtnstatus = true;
          setTimeout(() => {
            this.mobile_otp_status = 1;
          }, 480000);
          // 8 minutes set timeout
        }
      });
    },
    startMobileTimer() {
      this.timerMobileStatus = true;
      if (this.timerMobileCount > 0) {
        setTimeout(() => {
          this.timerMobileCount--;
          this.startMobileTimer();
        }, 1000);
        const minutes = Math.floor(this.timerMobileCount / 60);
        let seconds = this.timerMobileCount % 60;
        if (seconds < 10) {
          this.timerMobileInterval = `0${minutes}:0${seconds}`;
        } else {
          this.timerMobileInterval = `0${minutes}:${seconds}`;
        }
      } else {
        this.timerMobileCount = 120;
        this.timerMobileStatus = false;
        this.resentotpbtnstatus = true;
      }
    },
    // Get Aadhar Detail Form Function end here
    // family hierarchy view detail start here
    voterFamilyRelationList() {
      this.ApiService.voterFamilyRelationList({ ak1: this.familyrelationstatus }).then((data) => {
        if (data.success == true) {
          this.familyTabDetails = data.data;
        } else {
          this.familyTabDetails = '';
        }
      });
    },
    // family hierarchy view detail start here
    // whatsApp modal function start here
    whatsappHistoryModalOpen(e) {
      this.whatsApprowId = e.ak1;
      this.whatsappname = e.ak5;
      this.whatsappnumber = e.ak24;
      this.whatsappvoterprofile = e.ak90;
      this.whatsappaadharprofile = e.ak33;
      this.disabledWhatsAppInputPermission = e.whatsapp;
      this.whatsappmodalsflag = true;
      this.getwhatsappchatlogs();
    },
    getwhatsappchatlogs() {
      this.showloadermodal = true;
      this.chatInput = "";
      this.file1 = "";
      this.uploadfilename = "";
      this.ApiService.getwhatsappchatlogs({ lr13: this.whatsApprowId }).then((data) => {
        if (data.success == true) {
          this.showloadermodal = false;
          this.whatsAppChatHistory = data.records;
        }
      });
      this.scrollmessage();
    },
    whatsappHistoryModalClose() {
      this.whatsappmodalsflag = false;
      clearInterval(this.timer3);
      this.ApiLoadingSwitch = false;
    },
    switchLoadingStatus() {
      if (this.ApiLoadingSwitch == true) {
        this.timer3 = window.setInterval(() => {
          this.getwhatsappchatlogstimer();
        }, 2000);
      } else {
        clearInterval(this.timer3);
      }
    },
    getwhatsappchatlogstimer() {
      this.ApiService.getwhatsappchatlogs({ lr13: this.rowId }).then((data) => {
        if (data.success == true) {
          this.whatsAppChatHistory = data.records;
        }
      });
    },
    sendMessage() {
      const formData = new FormData();
      formData.append("mobile_number", this.whatsappnumber);
      formData.append("voter_fk", this.whatsApprowId);
      formData.append("voter_fk_value", this.whatsappname);
      if (this.chatInput) {
        formData.append("message", this.chatInput);
      }
      if (this.file1) {
        formData.append("media", this.file1);
      }
      if (this.disabledWhatsAppInputPermission == 0) {
        formData.append("session_message", 0);
      } else {
        formData.append("session_message", 1);
      }
      // for (var pair of formData.entries()) {
      //       console.log(pair[0] + ' - ' + pair[1]);
      //   }
      this.showloaderbtn = true;
      this.ApiService.sendwhatsappmessage(formData).then((data) => {
        if (data.success == true) {
          this.showloaderbtn = false;
          this.chatInput = "";
          this.file1 = "";
          this.uploadfilename = "";
          this.getwhatsappchatlogs();
          this.scrollmessage();
        } else {
          this.showloaderbtn = false;
          this.chatInput = "";
          this.file1 = "";
          this.uploadfilename = "";
          this.getwhatsappchatlogs();
        }
      }
      );
    },
    validateAttachment(e) {
      var fileData = e.target.files[0];
      this.uploadfilename = fileData.name;
      if (this.$refs.uploadfile.files.length > 3) {
        this.$toast.open({
          message: "Maximum 3 files are allowed",
          type: "success",
          duration: 3000,
          position: "top-right",
        });
      } else {
        let len = this.$refs.uploadfile.files.length;
        if (len === 1) {
          this.file1 = this.$refs.uploadfile.files[0];
        } else if (len === 2) {
          this.file1 = this.$refs.uploadfile.files[0];
          this.file2 = this.$refs.uploadfile.files[1];
        } else if (len === 3) {
          this.file1 = this.$refs.uploadfile.files[0];
          this.file2 = this.$refs.uploadfile.files[1];
          this.file3 = this.$refs.uploadfile.files[2];
        }
      }
    },
    scrollmessage() {
      setTimeout(() => {
        var messageBody = document.querySelector('#chatcontainer');
        messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
      }, 500);
    },
    whatsappimagepreview(data) {
      this.previewimg = data.lr20;
      this.ImagePreviewDialog = true;
    },
    whatsappimagepreviewClose() {
      this.ImagePreviewDialog = false;
    },
    // WhatsApp Modal function end here
    checkVoterId() {
      var voterlength = this.votercardnumber.length;
      if (voterlength == 10) {
        var errorflag = 0;
        for (var i = 0, len = this.voterTabDetails.length; i < len; i++) {
          if (this.voterTabDetails[i]['ak35'] === this.votercardnumber.toUpperCase()) {
            errorflag = 1;
            break;
          }
        }
        if (errorflag == 1) {
          this.alreadyExitVoterMessage = 'This Voter Id is already added to your account!';
          this.errStatus = true;
          this.votercardnumber.length == 1;
        } else {
          this.alreadyExitVoterMessage = '';
          this.errStatus = false;
          this.votercardnumber.length == 0;
        }
      } else {
        this.alreadyExitVoterMessage = '';
        this.errStatus = true;
        this.votercardnumber.length == 1;
      }
    },
    checkAadharNumber() {
      if (this.alreadyExitMessage != '') {
        this.alreadyExitMessage = '';
      } else {
        return true;
      }
    },
    createVoterAddCompanyName(event) {
      if (event && event.value) {
        if (Number.isInteger(event.value)) {
          this.edit.companyname = { value: event.value, label: event.label };
        } else {
          this.edit.companyname = { value: 0, label: event.label };
        }
      }
    },
    clearselection() {
      this.reasign.reassignpartno = null;
      this.reasign.reassigntouser = '';
      setTimeout(() => {
        this.v$.$reset();
      }, 0);
    },
    onlypincodeEditFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
      var editpincodeInput = document.getElementById("editpincodeInput");
      if ((editpincodeInput.value == '' || editpincodeInput.length > 1) && (keyCode == 48)) {
        $event.preventDefault();
      }
    },
    onlypincodeFilterFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
      var filterpincodeInput = document.getElementById("filterpincodeInput");
      if ((filterpincodeInput.value == '' || filterpincodeInput.length > 1) && (keyCode == 48)) {
        $event.preventDefault();
      }
    },
    onlymobileEditFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }

      var editmobileInput = document.getElementById("editmobileInput");
      if ((editmobileInput.value == '' || editmobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
        $event.preventDefault();
      }
    },
    firstCapitalize(e) {
      const str = e;
      const arr = str.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

      }
      const str2 = arr.join(" ");
      return str2;
    },
    decodeHtml(html) {
      return JSON.parse('"' + html + '"');
    },
    CheckAllowDeleteVoterAccess(e) {
      if (e == true) {
        this.ApiService.checkAllowToDelete(this.allow.displaydeletevoterpermission).then((data) => {
          if (data.status == 200) {
            this.deletevotermessage = data.message;
            this.allow.displaydeletevoterpermission = 0;
            this.allow.displaydeletevoterpermission = false;
          } else {
            this.deletevotermessage = '';
            this.allow.displaydeletevoterpermission = 1;
            this.allow.displaydeletevoterpermission = true;
          }
        });
      } else if (e == false) {
        this.allow.displaydeletevoterpermission = 0;
        this.allow.displaydeletevoterpermission = false;
      }
    },
    confirmDeleteVoterModalOpen(e) {
      this.deletevoterfk = e;
      this.confirm_delete_voter_popup_status = true;
    },

    confirmDeleteVoterModalClose() {
      this.deletevoterfk = "";
      this.confirm_delete_voter_popup_status = false;
    },

    confirmDeleteVoterModalProcessBtn() {
      this.showconfirmdeleteloaderbtn = true;
      this.ApiService.deleteVoter({ ak1: this.deletevoterfk.ak1 }).then((data) => {
        if (data.success == true) {
          this.showconfirmdeleteloaderbtn = false;
          var successMsg = data.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.deletevoterfk = "";
          this.getvoterlistreload();
          this.confirm_delete_voter_popup_status = false;
          setTimeout(() => {
            this.v$.$reset();
          }, 0);
        } else {
          this.showconfirmdeleteloaderbtn = false;
          var errorMsg = data.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        }
      });
    },
    // webbot history modal start here
    webbotHistoryModalOpen(e) {
        this.webbotrowId = e.ak1;
        this.webbotvoterprofile = e.ak90;
        this.webbotaadharprofile = e.ak33;
        this.webbotname = e.ak5;
        this.webbotnumber = e.ak24;
        this.disabledWhatsAppInputPermission = e.whatsapp;
        this.webbotmodalsflag = true;
        this.getWebBotchatlogs();
    },
    getWebBotchatlogs() {
        this.showwebbotloadermodal = true;
        this.ApiService.getWebBotchatlogs({ lo11: this.webbotrowId }).then((data) => {
            if (data.success == true) {
                this.showwebbotloadermodal = false;
                this.webbotChatHistory = data.records;
                this.scrollwebmessage();
            } else {
              this.showwebbotloadermodal = false;
            }
        });
    },
    webbotHistoryModalClose() {
        this.webbotmodalsflag = false;
        clearInterval(this.timer7);
        this.ApiWebBotLoadingSwitch = false;
    },
    switchWebLoadingStatus() {
        if (this.ApiWebBotLoadingSwitch == true) {
            this.timer7 = window.setInterval(() => {
                this.getwebchatlogstimer();
            }, 2000);
        } else {
            clearInterval(this.timer7);
        }
    },
    getwebchatlogstimer() {
    this.ApiService.getWebBotchatlogs({ lo11: this.webbotrowId }).then((data) => {
        if (data.success == true) {
            this.webbotChatHistory = data.records;
        }
    });
    },
    format_timestamptime(value) {
        if (value) {
            var localTime = new Date(value * 1000).toUTCString();
            return moment(localTime).format("hh:mm a");
        } else {
            return 'N/A';
        }
    },
    replacebrake(html) {
        const doc = html;
        return doc.replace(/(\r)*\n/g, '<br>')
    },
    scrollwebmessage() {
        setTimeout(() => {
            var webmessageBody = document.querySelector('#webchatcontainer');
            if (webmessageBody != null) {
                webmessageBody.scrollTop = webmessageBody.scrollHeight;
            }
        }, 1000);
    },
    // webbot history modal end here
  },
};